import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { AppState, SceneData } from "../../packages/excalidraw/types";

export interface UpdateScene {
  scene: <K extends keyof AppState>(sceneData: {
    elements?: SceneData["elements"];
    appState?: Pick<AppState, K> | null | undefined;
    collaborators?: SceneData["collaborators"];
    commitToHistory?: SceneData["commitToHistory"];
  }) => void;
}
interface SidePeekInterface {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  pageId: string;
  setPageId: Dispatch<SetStateAction<string>>;
  publicUrl: string;
  setPublicUrl: Dispatch<SetStateAction<string>>;
  setUpdateElement: Dispatch<SetStateAction<UpdateScene | undefined>>;
  updateElement: UpdateScene | undefined;
}

const sidePeekContext = createContext({ show: false } as SidePeekInterface);
const { Provider } = sidePeekContext;

export function SidePeekProvider(props: { children: ReactNode }): JSX.Element {
  const sidePeek = useSidePeekProvider();
  return <Provider value={sidePeek}>{props.children}</Provider>;
}

export const useSidePeek = () => {
  return useContext(sidePeekContext);
};

// Provider hook that creates auth object and handles state
const useSidePeekProvider = () => {
  const [show, setShow] = useState(false);
  const [pageId, setPageId] = useState("");
  const [publicUrl, setPublicUrl] = useState("");
  const [updateElement, setUpdateElement] = useState<UpdateScene>();

  return {
    show,
    setShow,
    pageId,
    setPageId,
    publicUrl,
    setPublicUrl,
    updateElement,
    setUpdateElement,
  };
};
