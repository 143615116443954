export const setLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const getLocalStorage = (key: string) => {
  return localStorage.getItem(key) || null;
};

export const removeLocalStorage = (keys: string[]) => {
  keys.forEach((key) => localStorage.removeItem(key));
};
