import { Button } from "../../../packages/excalidraw";
import { useAuth } from "../../hooks/useAuth";

const OAuthIntegration: React.FC = ({ ...rest }) => {
  const { session, signIn, signOut } = useAuth();

  const handleNotionIntegration = async () => {
    await signIn("notion");
  };
  if (session) {
    return (
      <div className="integration">
        <Button
          {...rest}
          className="notion-integration-btn"
          type="button"
          onSelect={signOut}
        >
          Logout
        </Button>
      </div>
    );
  }
  return (
    <div className="integration">
      <Button
        {...rest}
        className="notion-integration-btn"
        type="button"
        onSelect={handleNotionIntegration}
      >
        Connect with Notion
      </Button>
    </div>
  );
};
export default OAuthIntegration;
