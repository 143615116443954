import {
  ChangeEvent,
  Dispatch,
  useEffect,
  useState,
  SetStateAction,
} from "react";
import { CommandPaletteRendererInterface } from "../../../../packages/excalidraw/element/types";
import { notionDatabase, notionSearch } from "../../../services/notion";
import {
  NotionIconElementType,
  getPageTitle,
  processNotionIcon,
} from "../../../helper/canvasUtils";
import { MouseEventType, NotionRawPage } from "../../../types";
import {
  ArrowUturnLeftIcon,
  ArrowsUpDownIcon,
} from "@heroicons/react/24/outline";
import {
  handleSupabaseError,
  handleUpdateScene,
  storeArrowElements,
} from "../../../services/utils";
import { classNames } from "../../../helper/classNames";
interface NotionPage {
  id?: string;
  title?: string;
  type?: string;
  icon?: {
    type: string;
    [key: string]: string;
  };
  publicUrl: string | null;
}
interface Props extends CommandPaletteRendererInterface {
  mouseClickEvent: MouseEventType;
  eventType?: string;
  closeModal: () => void;
  setSelectedPage?: Dispatch<SetStateAction<NotionPage>>;
  selectedPage?: NotionPage;
  renderPage?: boolean;
  element?: any;
}

const Search: React.FC<Props> = ({
  render,
  mouseClickEvent,
  closeModal,
  eventType,
  setSelectedPage,
  selectedPage,
  renderPage = false,
  element,
  excalidrawAppState,
}) => {
  const [query, setQuery] = useState("");
  const [error, setError] = useState<any>();
  const [pageList, setPageList] = useState<NotionPage[]>([] as NotionPage[]);
  const [isLoading, setIsLoading] = useState(false);
  const [recentSearch, setRecentSearch] = useState([] as any[]);
  const [searchElementIndex, setSearchElementIndex] = useState(0);
  const [searchIndexVisible, setSearchIndexVisible] = useState(true);
  useEffect(() => {
    setRecentSearch(getRecentSearches());
  }, []);

  const handlePageImport = async (selectedPage: NotionPage) => {
    if (
      mouseClickEvent?.pointerDownCoordinates?.length === 2 &&
      selectedPage.id
    ) {
      if (element && eventType === "linkNotionPageForExisting") {
        storeArrowElements();
        await drawNotionPage();
        setTimeout(() => {
          handleUpdateScene(excalidrawAppState, element);
        }, 300);
      } else if (selectedPage.type === "database") {
        await drawNotionDb();
      } else {
        await drawNotionPage();
      }
    }

    async function drawNotionPage() {
      render({
        type: "notion",
        integrationSpecificData: {
          title: selectedPage.title || "Untitled",
          pageId: selectedPage.id || "",
          pageType: selectedPage.type || "",
          icon: selectedPage.icon,
          publicUrl: selectedPage.publicUrl,
        },
        pointerDownCoords: mouseClickEvent.pointerDownCoordinates,
      });
      setError(null);
      closeModal();
    }

    async function drawNotionDb() {
      setIsLoading(true);
      const { data: dbPages, error: notionDBError } = await notionDatabase(
        selectedPage.id ?? "",
      );
      notionDBError &&
        handleSupabaseError(notionDBError, setError, setIsLoading);

      if (dbPages?.results) {
        const { results: collectionPages } = dbPages;
        for (const notionPageObject of collectionPages as NotionRawPage[]) {
          mouseClickEvent.pointerDownCoordinates[0] += 50;
          mouseClickEvent.pointerDownCoordinates[1] += 50;
          render({
            type: "notion",
            integrationSpecificData: {
              title: getPageTitle(notionPageObject),
              pageId: notionPageObject.id,
              pageType: notionPageObject.object,
              icon: notionPageObject.icon,
              publicUrl: notionPageObject.public_url,
            },
            pointerDownCoords: mouseClickEvent.pointerDownCoordinates,
          });
          setError(null);
          closeModal();
        }
        setIsLoading(false);
      }
    }
  };

  const handleNotionSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    const searchQuery = event.target.value;
    setQuery(searchQuery);
    setSelectedPage &&
      setSelectedPage((prev) => ({
        ...prev,
        id: "",
        title: "",
        type: "",
      }));
    if (searchQuery.trim().length >= 3) {
      setIsLoading(true);
      const { data: searchData, error: searchError } = await notionSearch({
        query: searchQuery,
      });
      searchError && handleSupabaseError(searchError, setError, setIsLoading);
      if (searchData) {
        setPageList(
          searchData.map((page: NotionRawPage) => {
            return {
              id: page.id,
              title: getPageTitle(page),
              type: page.object,
              icon: page.icon,
              publicUrl: page.public_url,
            };
          }),
        );
      }
      setIsLoading(false);
    }
  };

  const getRecentSearches = () => {
    const recentSearches = localStorage.getItem("recentSearches");
    return recentSearches ? JSON.parse(recentSearches) : [];
  };

  const addRecentSearch = (searchTerm: any) => {
    const recentSearches = getRecentSearches();
    if (Array.isArray(recentSearch)) {
      recentSearches.unshift(searchTerm);
      const uniqueMap = recentSearches.reduce((acc: any, obj: NotionPage) => {
        if (obj?.id) {
          acc[obj.id] = obj;
          return acc;
        }
        return {};
      }, {});
      const uniqueSearchData = Object.values(uniqueMap);
      if (uniqueSearchData.length >= 6) {
        uniqueSearchData.pop();
      }
      localStorage.setItem("recentSearches", JSON.stringify(uniqueSearchData));
    }
  };

  const handleSelectPage = (page: NotionPage) => {
    addRecentSearch(page);
    if (
      eventType === "addNotionPage" ||
      renderPage ||
      eventType === "createNotionPageForExisting"
    ) {
      setSelectedPage && setSelectedPage(page);
      setQuery(page?.title as string);
    } else if (eventType === "importFromNotion" || !renderPage) {
      handlePageImport(page);
    }
  };

  const scrollInto = (viewIndex: number) => {
    const selectedElement = document.getElementById(`page-${viewIndex}`);
    if (selectedElement) {
      selectedElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const handleKeyboard = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!searchIndexVisible) {
      return;
    }
    switch (event.code) {
      case "Escape":
        closeModal();
        return;
      case "ArrowUp":
        setSearchElementIndex((prevIndex) =>
          prevIndex === 0 ? pageList.length - 1 : prevIndex - 1,
        );
        scrollInto(searchElementIndex);
        return;
      case "ArrowDown":
        setSearchElementIndex((prevIndex) =>
          prevIndex === pageList.length - 1 ? 0 : prevIndex + 1,
        );
        scrollInto(searchElementIndex);
        return;
      case "Enter":
        handleSelectPage(pageList[searchElementIndex]);
        break;
      default:
        break;
    }
  };

  const handleShow = () => {
    if (
      eventType === "addNotionPage" ||
      eventType === "createNotionPageForExisting"
    ) {
      return true;
    } else if (renderPage) {
      return true;
    }
    return false;
  };

  const renderResult = (page: NotionPage) => {
    return (
      <div className="flex w-full justify-between">
        <div className="flex items-center gap-1">
          <div className="w-6 h-6 mx-1 flex items-center justify-start">
            {processNotionIcon(page.icon as NotionIconElementType)}
          </div>
          <p>{page.title}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        <input
          className="border-[1.5px] border-gray-400 rounded-md w-full py-3 px-4 text-md focus-visible:border-[1.5px] focus-visible:border-indigo-400 focus-visible:outline-0"
          type="text"
          placeholder="Search notion pages"
          onChange={handleNotionSearch}
          onKeyDown={handleKeyboard}
          value={query}
        />
        {pageList.length ? (
          <div className="flex mt-10 justify-center gap-10">
            <div className="flex items-center gap-2 rounded-md">
              <ArrowsUpDownIcon className="h-6 p-1 bg-indigo-100  w-6 rounded-md text-gray-900" />
              <span className="text-xs text-gray-400">Select</span>
            </div>
            <div className="flex items-center gap-2 rounded-md">
              <ArrowUturnLeftIcon className="h-6 p-1 bg-indigo-100  w-6 rounded-md text-gray-900" />
              <span className="text-xs text-gray-400">Confirm</span>
            </div>
            <div className=" flex items-center gap-2 rounded-md">
              <p className="p-1 text-xs px-2 rounded-md text-gray-900 bg-indigo-100">
                Esc
              </p>
              <span className="text-xs text-gray-400">Close</span>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {error && !isLoading && (
        <p className="text-center py-3 text-sm text-red-400">{error}</p>
      )}
      <div className="mt-5">
        <>
          {!isLoading && pageList.length && !selectedPage?.id ? (
            <>
              <h4 className="text-lg pb-4 text-gray-800">Suggestions</h4>
              <div className="overflow-y-scroll z-50 h-auto max-h-[40vh] ">
                {pageList?.map((page, index) => (
                  <div
                    onMouseEnter={() => setSearchIndexVisible(false)}
                    onMouseLeave={() => {
                      setSearchIndexVisible(true);
                      setSearchElementIndex(index);
                    }}
                    className={classNames(
                      searchIndexVisible && searchElementIndex === index
                        ? " bg-gray-100"
                        : "",
                      !element
                        ? "hover:bg-gray-100  py-3 px-2"
                        : (element && page.type !== "database") ||
                          eventType === "createNotionPageForExisting"
                        ? " py-3 px-2 hover:bg-gray-100"
                        : "",
                      " w-full flex justify-between items-center gap-2 hover:cursor-pointer rounded-md text-gray-600 text-sm",
                    )}
                    key={index}
                    id={`page-${index}`}
                    onClick={() => handleSelectPage(page)}
                  >
                    {page && page.type === "database" ? (
                      <>
                        {(!element ||
                          eventType === "createNotionPageForExisting") && (
                          <div className="flex w-full justify-between">
                            {renderResult(page)}
                            <div className="text-gray-500 w-full text-end rounded-md">
                              {!handleShow()
                                ? `Import Entire ${page.type}`
                                : page.type}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      renderResult(page)
                    )}
                  </div>
                ))}
              </div>
            </>
          ) : error && !isLoading ? (
            <p className="text-center py-3 text-sm text-red-400">{error}</p>
          ) : !isLoading && query.trim().length >= 3 && !selectedPage?.id ? (
            <p className="text-center text-gray-500 py-3 text-sm">
              No results found
            </p>
          ) : (
            ""
          )}
          {isLoading && query && (
            <div className="py-3 px-5  mt-10 flex items-center justify-center w-full gap-2 text-sm rounded-md text-gray-700 my-2">
              <svg
                aria-hidden="true"
                className="inline w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-indigo-500"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              Loading...
            </div>
          )}
          {!pageList.length &&
          !selectedPage?.id &&
          !isLoading &&
          query.trim() === "" &&
          recentSearch.length ? (
            <div>
              <h4 className="text-lg pb-4 text-gray-800">Recent search</h4>
              {recentSearch?.map((page: NotionPage, index: number) => (
                <div
                  onClick={() => handleSelectPage(page)}
                  className={classNames(
                    !element
                      ? "hover:bg-gray-100  py-3 px-2"
                      : (element && page.type !== "database") ||
                        eventType === "createNotionPageForExisting"
                      ? " py-3 px-2 hover:bg-gray-100"
                      : "",
                    " w-full flex justify-between items-center gap-2 hover:cursor-pointer rounded-md text-gray-600 text-sm",
                  )}
                  key={index}
                >
                  {page && page.type === "database" ? (
                    <>
                      {(!element ||
                        eventType === "createNotionPageForExisting") && (
                        <>
                          {renderResult(page)}
                          <div className="text-gray-500 w-full text-end rounded-md">
                            {!handleShow()
                              ? `Import Entire ${page.type}`
                              : page.type}
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    renderResult(page)
                  )}
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </>
      </div>
    </>
  );
};

export default Search;
