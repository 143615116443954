import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ExcalidrawApp from "./App";
import { registerSW } from "virtual:pwa-register";
import "./tailwind.css";

import "../excalidraw-app/sentry";
import { AuthProvider } from "./hooks/useAuth";
// import AuthVerify from "./pages/auth/verify";
import { SidePeekProvider } from "./hooks/useSidePeek";
window.__EXCALIDRAW_SHA__ = import.meta.env.VITE_APP_GIT_SHA;
const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);
registerSW();

const router = createBrowserRouter([
  {
    path: "/",
    element: <ExcalidrawApp />,
  },
  // {
  // path: "/auth/verify",
  // element: <AuthVerify />,
  // },
]);

root.render(
  <StrictMode>
    <AuthProvider>
      <SidePeekProvider>
        <RouterProvider router={router}></RouterProvider>
      </SidePeekProvider>
    </AuthProvider>
  </StrictMode>,
);
