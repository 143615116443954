import { FunctionsHttpError } from "@supabase/supabase-js";
import { deleteSelectedElements } from "../../packages/excalidraw/actions/actionDeleteSelected";
import { fixBindingsAfterDeletion } from "../../packages/excalidraw/element/binding";
import { updateActiveTool } from "../../packages/excalidraw/utils";
import { isSomeElementSelected } from "../../packages/excalidraw/scene";
import { getNonDeletedElements } from "../../packages/excalidraw";
import { handleGroupEditingState } from "../../packages/excalidraw/actions/actionDeleteSelected";
import { STORAGE_KEYS } from "../app_constants";
import { ExcalidrawElement } from "../../packages/excalidraw/element/types";
import { AppState } from "../../packages/excalidraw/types";
import { ElementType } from "../components/canvas/CommandPalette";
export const handleSupabaseError = async (
  error: unknown,
  setError: React.Dispatch<React.SetStateAction<any>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  if (error && error instanceof FunctionsHttpError) {
    const errorMessage = await error.context.json();
    setError(errorMessage.error);
    setIsLoading(false);
  }
};

const deleteUpdateElement = (
  excalidrawAppState: AppState | undefined,
  elements: ExcalidrawElement[],
) => {
  if (excalidrawAppState) {
    let { elements: nextElements, appState: nextAppState } =
      deleteSelectedElements(elements, excalidrawAppState);
    fixBindingsAfterDeletion(
      nextElements,
      elements.filter(
        ({ id }: ExcalidrawElement) =>
          excalidrawAppState.selectedElementIds[id],
      ),
    );
    nextAppState = handleGroupEditingState(nextAppState, nextElements);
    return {
      elements: nextElements,
      appState: {
        ...nextAppState,
        activeTool: updateActiveTool(excalidrawAppState, {
          type: "selection",
        }),
        multiElement: null,
        activeEmbeddable: null,
      },
      commitToHistory: isSomeElementSelected(
        getNonDeletedElements(elements),
        excalidrawAppState,
      ),
    };
  }
};

export const elementsDataStore = () => {
  const data = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS);
  return data && JSON.parse(data);
};

export const storeArrowElements = () => {
  const elements = elementsDataStore();
  if (elements.length) {
    const data = elements.filter(
      (item: ExcalidrawElement) => item.type === "arrow",
    );
    localStorage.setItem(
      STORAGE_KEYS.LOCAL_STORAGE_ARROWS,
      JSON.stringify(data),
    );
  }
};

export const handleUpdateScene = (
  excalidrawAppState: AppState | undefined,
  element: ElementType,
) => {
  const elements = elementsDataStore();
  const newNotionElement = elements[elements.length - 1];

  const updateElements =
    excalidrawAppState && deleteUpdateElement(excalidrawAppState, elements);

  const deleteElement: ExcalidrawElement[] | undefined | any =
    updateElements?.elements?.filter(
      (item: ExcalidrawElement) => item.isDeleted && item.type !== "text",
    );

  newNotionElement.boundElements =
    deleteElement && deleteElement[0].boundElements;

  newNotionElement.width = deleteElement[0].width;
  newNotionElement.height = deleteElement[0].height;

  const bindingArrows = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_ARROWS);
  const bind = bindingArrows && JSON.parse(bindingArrows);

  if (bind.length && newNotionElement?.boundElements) {
    const bindArrowElements = bind?.filter((arrow: ExcalidrawElement) =>
      newNotionElement.boundElements.some(
        (boundObj: ExcalidrawElement) => boundObj.id === arrow.id,
      ),
    );

    const endBindingArrows = bindArrowElements
      .map((obj: any) => {
        const startbind_id = obj?.startBinding?.elementId;
        const endbind_id = obj?.endBinding?.elementId;
        if (
          deleteElement &&
          startbind_id &&
          startbind_id === deleteElement[0].id &&
          newNotionElement
        ) {
          obj.startBinding.elementId = newNotionElement.id;
          return obj;
        } else if (
          deleteElement &&
          endbind_id &&
          endbind_id === deleteElement[0].id &&
          newNotionElement
        ) {
          obj.endBinding.elementId = newNotionElement.id;
          return obj;
        }
        return null;
      })
      .filter((item: ExcalidrawElement) => item !== null);

    const updateElementsData = updateElements?.elements.filter(
      (element) =>
        !endBindingArrows.some(
          (arrow: ExcalidrawElement) => arrow.id === element.id,
        ),
    );

    endBindingArrows.forEach((element: any) => {
      updateElementsData?.push(element);
    });

    if (updateElementsData && updateElements) {
      updateElements.elements = updateElementsData;
    }
  }
  element.updateScene(updateElements);
  localStorage.removeItem(STORAGE_KEYS.LOCAL_STORAGE_ARROWS);
};
