import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import {
  CommandPaletteRendererInterface,
  ExcalidrawElement,
} from "../../../packages/excalidraw/element/types";
import {
  subscribeEvent,
  unSubscribeEvent,
} from "../../../packages/excalidraw/events";
import { MouseEventType } from "../../types";
import SearchNotionPage from "./notion/Search";
import CreatePage from "./notion/CreatePage";
import NotionPalette from "./notion/NotionPalette";
import { viewportCoordsToSceneCoords } from "../../../packages/excalidraw";
import { useAuth } from "../../hooks/useAuth";
import Authentication from "./notion/Authentication";

export interface ElementType {
  element: ExcalidrawElement;
  updateScene: (data: any) => void;
};
const CommandPalette: React.FC<CommandPaletteRendererInterface> = ({
  render,
  excalidrawAppState,
}) => {
  const [mouseClickEvent, setMouseClickEvent] = useState<MouseEventType>({
    top: 0,
    left: 0,
    event: null,
    pointerDownCoordinates: [0, 0],
  });
  const [eventType, setEventType] = useState("");

  const [openOmnibar, setOpenOmnibar] = useState(false);
  const [element, setElement] = useState<ElementType>();

  useEffect(() => {
    if (eventType === "notion") {
      setMouseClickEvent((prev) => ({
        ...prev,
        pointerDownCoordinates: [0, 0],
      }));
    }
  }, [eventType]);

  const handleMouseClickEvent = (event: CustomEvent) => {
    setMouseClickEvent(event.detail);
    setElement((prev) => ({
      ...prev,
      element: event.detail.element,
      updateScene: event.detail.updateScene,
    }));
  };

  const handleModalOpenEvent = (event: CustomEvent) => {
    if (event?.detail?.type) {
      setOpenOmnibar(true);
      setEventType(event?.detail?.type);
    }
  };

  useEffect(() => {
    subscribeEvent("contextMenuOpened", handleMouseClickEvent);
    subscribeEvent("contextOptionSelected", handleModalOpenEvent);
    subscribeEvent("toolbarOptionSelected", handleModalOpenEvent);
    return () => {
      unSubscribeEvent("contextMenuOpened", handleMouseClickEvent);
      unSubscribeEvent("contextOptionSelected", handleModalOpenEvent);
      subscribeEvent("toolbarOptionSelected", handleModalOpenEvent);
    };
  }, []);

  const closeModal = () => {
    setOpenOmnibar(false);
    setEventType("");
  };

  useEffect(() => {
    if (eventType === "notion") {
      const handler = (event: any) => {
        const appState = excalidrawAppState;
        if (appState) {
          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              const sceneCoords = viewportCoordsToSceneCoords(event, appState);
              setMouseClickEvent((prev) => ({
                ...prev,
                pointerDownCoordinates: [sceneCoords.x, sceneCoords.y],
              }));
            });
          });
        }
      };
      document.addEventListener("pointermove", handler);
      return () => {
        document.removeEventListener("pointermove", handler);
      };
    }
  }, [excalidrawAppState, eventType]);

  useEffect(() => {
    if (
      (eventType === "linkNotionPageForExisting" ||
        eventType === "createNotionPageForExisting") &&
      element
    ) {
      setMouseClickEvent((prev) => ({
        ...prev,
        pointerDownCoordinates: [element.element.x, element.element.y],
      }));
    }
  }, [element, eventType]);

  const handleShowModal = (eventType: string) => {
    const commonProps = {
      render,
      mouseClickEvent,
      closeModal,
      excalidrawAppState,
    };
    switch (eventType) {
      case "importFromNotion":
        return <SearchNotionPage {...commonProps} />;
      case "addNotionPage":
        return <CreatePage {...commonProps} eventType={eventType} />;
      case "notion":
        return <NotionPalette eventType={eventType} {...commonProps} />;

      case "linkNotionPageForExisting":
        return (
          <SearchNotionPage
            {...commonProps}
            eventType={eventType}
            element={element}
          />
        );
      case "createNotionPageForExisting":
        return (
          <CreatePage
            {...commonProps}
            eventType={eventType}
            element={element}
          />
        );
      default:
        return <></>;
    }
  };
  const { session } = useAuth();

  return (
    <Transition appear show={openOmnibar} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {eventType ? (
                <Dialog.Panel
                  className={`w-full ${
                    !session ? "max-w-sm" : "max-w-2xl"
                  } transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all`}
                >
                  {!session ? <Authentication /> : handleShowModal(eventType)}
                </Dialog.Panel>
              ) : (
                <></>
              )}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CommandPalette;
