import { IntegrationRendererInterface } from "../../../../packages/excalidraw/element/types";
import { RenderNotion } from "./RenderNotion";

export const RenderIntegration: React.FC<IntegrationRendererInterface> = ({
  element,
  parentStyle,
  updateScene,
}) => {
  if (element.type === "notion") {
    return (
      <RenderNotion
        element={element}
        parentStyle={parentStyle}
        updateScene={updateScene}
      ></RenderNotion>
    );
  }
  return <></>;
};
