import { useState } from "react";
import { CommandPaletteRendererInterface } from "../../../../packages/excalidraw/element/types";
import { MouseEventType } from "../../../types";
import Search from "./Search";
import { DocumentArrowUpIcon } from "@heroicons/react/24/outline";
import CreatePage from "./CreatePage";

interface Props extends CommandPaletteRendererInterface {
  mouseClickEvent: MouseEventType;
  eventType?: string;
  closeModal: () => void;
}

const NotionPalette: React.FC<Props> = ({
  render,
  mouseClickEvent,
  closeModal,
  eventType,
}) => {
  const [renderPage, setRenderPage] = useState(false);
  return (
    <>
      <div>
        {!renderPage ? (
          <Search
            render={render}
            mouseClickEvent={mouseClickEvent}
            closeModal={closeModal}
            eventType={eventType}
            renderPage={renderPage}
          />
        ) : (
          <CreatePage
            render={render}
            mouseClickEvent={mouseClickEvent}
            eventType={eventType}
            closeModal={closeModal}
            renderPage={renderPage}
          />
        )}
      </div>
      <div className="border-t-2 mt-5  pt-6">
        <div></div>
        <div
          onClick={() => setRenderPage(!renderPage)}
          className="flex cursor-pointer  items-center gap-1 hover:bg-gray-100 py-3 px-3 rounded-md"
        >
          <DocumentArrowUpIcon className="w-5 h-5 text-gray-500" />
          {!renderPage ? (
            <h4 className="text-sm text-gray-700">Create new page in notion</h4>
          ) : (
            <h4 className="text-sm text-gray-700">Import Notion page</h4>
          )}
        </div>
      </div>
    </>
  );
};

export default NotionPalette;
