import { Transition } from "@headlessui/react";
import { useSidePeek } from "../../hooks/useSidePeek";
import { NotionRenderer } from "react-notion-x";
import { Code } from "react-notion-x/build/third-party/code";
import { Equation } from "react-notion-x/build/third-party/equation";
import { Collection } from "react-notion-x/build/third-party/collection";
import { Pdf } from "react-notion-x/build/third-party/pdf";
import { Modal } from "react-notion-x/build/third-party/modal";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { notionPage, notionPageRecordMap } from "../../services/notion";
import { ExtendedRecordMap } from "notion-types";
import "react-notion-x/src/styles.css";
import {
  ChevronDoubleLeftIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { handleSupabaseError } from "../../services/utils";
import { getLocalStorage } from "../../helper/localStorage";
import { STORAGE_KEYS } from "../../app_constants";
import { useAuth } from "../../hooks/useAuth";
import { getPageTitle } from "../../helper/canvasUtils";
// import { getLocalStorage } from "../../helper/localStorage";
// import { STORAGE_KEYS } from "../../app_constants";
// import Iframe from "./Iframe";

const NotionSidePeek: React.FC = () => {
  const { show, pageId, publicUrl, updateElement } = useSidePeek();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>();
  const { setPageId, setShow } = useSidePeek();
  const { session } = useAuth();

  const [recordMap, setRecordMap]: [
    ExtendedRecordMap | undefined,
    Dispatch<SetStateAction<ExtendedRecordMap | undefined>>,
  ] = useState();

  const handleInternalLinks = (event: React.MouseEvent<HTMLDivElement>) => {
    // event.preventDefault();
    const container = document.getElementById("notion-side-peek");
    if (container) {
      let target = event.target as any;
      while (target && target !== container) {
        if (target.tagName?.toLowerCase() === "a") {
          event.preventDefault();
          window.open(target.href, "_blank");
          return;
        }
        target = target.parentNode;
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (isLoading && pageId && session) {
        const elements = getLocalStorage(STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS);
        const notionElements: any[] = elements && JSON.parse(elements);
        const currentNotion = notionElements.find(
          (item) => item.pageId === pageId,
        );
        const currentTime = Date.now();

        const runOnedayAfter = currentNotion?.notionUpdatedAt
          ? currentNotion?.notionUpdatedAt + 86400000
          : currentTime - 10000;
        if (currentTime >= runOnedayAfter) {
          const { data, error } = await notionPage(pageId);
          if (error) {
            console.log("error occurred update title and icon");
            return;
          }
          notionElements.forEach((ele) => {
            if (ele?.pageId === pageId) {
              ele.publicUrl = data?.public_url;
              ele.icon = data?.icon;
              ele.title = getPageTitle(data);
              ele.notionUpdatedAt = Date.now();
            }
          });
          updateElement?.scene({
            elements: notionElements,
            commitToHistory: true,
          });
        }
      }
    })();
  }, [isLoading, pageId, updateElement, session]);

  const handleNotionPage = useCallback(async () => {
    setIsLoading(true);
    const { data, error } = await notionPageRecordMap({
      pageId,
      publicUrl,
    });
    error && handleSupabaseError(error, setError, setIsLoading);

    if (data) {
      setRecordMap(data);
      setError(null);
    }
    setIsLoading(false);
  }, [pageId, publicUrl]);

  useEffect(() => {
    if (show) {
      handleNotionPage();
    }
  }, [handleNotionPage, pageId, show]);

  const notionInternalPageMap = (rootPageId?: string) => (pageId: string) => {
    pageId = (pageId || "").replace(/-/g, "");
    if (rootPageId && pageId === rootPageId) {
      return "/";
    }
    return `https://notion.so/${pageId}`;
  };
  return (
    <Transition
      show={show}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="absolute top-0 right-0 z-30 h-full w-[50vw] border-l-8 border--gr sidepeek-animation">
        <div className="bg-white h-full">
          <div className="fixed top-0 w-full">
            <button
              className="bg-gray-100 h-8 w-8 flex justify-center items-center m-2 rounded-lg transition-all"
              onClick={() => {
                setShow(false);
                setPageId("");
              }}
            >
              <ChevronDoubleLeftIcon width={20} height={20} />
            </button>
          </div>
          <div className="mt-10 h-full w-full absolute overflow-scroll">
            {isLoading ? (
              <div className="w-full h-full flex items-center justify-center">
                <span className="w-10 h-10 animate-spin">
                  <svg viewBox="0 0 100 100" fill="#6965db">
                    <path d="M90.9 58.2c-3.8 18.9-20.1 32.7-39.7 33-22 .4-40.7-17-41.9-39C8 28.5 26.9 8.8 50.4 8.8c19 0 35.5 13.1 40 31.2.3 1.2 1.4 2.1 2.7 2.1 1.8 0 3.1-1.7 2.7-3.5C90.6 18.1 72 3.3 50.4 3.3c-27.2 0-49 23.4-46.6 51.1 2.1 23 21 41.2 44 42.4C71.6 98 91.7 81.9 96.2 59.4c.3-1.7-1-3.3-2.7-3.3-1.3-.1-2.4.8-2.6 2.1z" />
                  </svg>
                </span>
              </div>
            ) : error ? (
              <div className="w-[50vw] h-full flex p-5 text-red-400 m-3 rounded-md text-xl flex-col items-center justify-center">
                <ExclamationCircleIcon className="w-16 h-16" />
                <p className=" mt-2">
                  {typeof error === "string" ? error : JSON.stringify(error)}
                </p>
              </div>
            ) : (
              recordMap && (
                <div
                  id="notion-side-peek"
                  onClick={handleInternalLinks}
                  className=" transition-all "
                >
                  <NotionRenderer
                    components={{ Pdf, Collection, Equation, Code, Modal }}
                    fullPage={true}
                    recordMap={recordMap}
                    mapPageUrl={notionInternalPageMap()}
                  />
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default NotionSidePeek;
