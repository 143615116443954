import { register } from "./register";
// import { AppState } from "../types";
import { dispatchCanvasEvent } from "../events";

export const actionAddNotionPage = register({
  name: "addNotionPage",
  viewMode: false,
  trackEvent: {
    category: "canvas",
    predicate: (appState) => !appState.gridSize,
  },
  perform(elements, appState) {
    dispatchCanvasEvent("contextOptionSelected", {
      type: "addNotionPage",
    });
    return {
      appState: {
        ...appState,
        open,
      },
      commitToHistory: false,
    };
  },
  predicate: (element, appState, props) => {
    //function to decide whether to show this option or not
    return typeof props.renderCommandPalette !== "undefined";
  },
  contextItemLabel: "buttons.notionPage", // right click option name
});
