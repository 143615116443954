import { SVGProps } from "react";
export const SidePeekOpenIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={14}
    fill="none"
    {...props}
  >
    <rect
      width={17}
      height={12.091}
      x={0.5}
      y={0.5}
      stroke="#55534E"
      rx={1.5}
    />
    <mask id="a" fill="#fff">
      <rect width={7} height={9} x={9} y={2} rx={0.7} />
    </mask>
    <rect
      width={7}
      height={9}
      x={9}
      y={2}
      fill="#55534E"
      stroke="#55534E"
      strokeWidth={2}
      mask="url(#a)"
      rx={0.7}
    />
  </svg>
);
