import { NotionEmptyIcon } from "../assets/NotionEmptyIcon";

export type NotionIconElementType =
  | { type: "emoji"; emoji: string }
  | { type: "external"; external: { url: string } }
  | { type: "file"; file: { url: string; expiry_time: string } };

export const getPageTitle = (page: any) => {
  let title = "";
  if (page?.title && Array.isArray(page?.title) && page?.title?.length) {
    title = page.title[0].plain_text;
  } else if (page?.properties) {
    const titleProp = Object.values(page.properties).find(
      (prop: any) => prop?.type === "title",
    );
    titleProp && (title = (titleProp as any).title?.[0]?.plain_text);
  }
  return title || "Untitled";
};

export const processNotionIcon = (icon: NotionIconElementType | null) => {
  if (!icon) {
    return <NotionEmptyIcon />;
  }
  if (icon.type === "emoji") {
    return (
      <div
        role="img"
        data-emoji={icon.emoji}
        tabIndex={0}
        style={{ cursor: "pointer" }}
      >
        {icon.emoji}
      </div>
    );
  }
  if (icon.type === "external") {
    icon.external.url = icon.external.url.startsWith("/")
      ? `https://notion.so/${icon.external.url}`
      : icon.external.url;
    return <img src={`${icon.external.url}`} alt="notion-icon"></img>;
  }
  if (icon.type === "file") {
    return <img src={`${icon.file.url}`} alt="notion-icon"></img>;
  }
  return <NotionEmptyIcon />;
};
