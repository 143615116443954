import { register } from "./register";
// import { AppState } from "../types";
import { dispatchCanvasEvent } from "../events";

export const actionNotion = register({
  name: "importFromNotion",
  viewMode: false,
  trackEvent: {
    category: "canvas",
    predicate: (appState) => !appState.gridSize,
  },
  perform(elements, appState) {
    dispatchCanvasEvent("contextOptionSelected", {
      type: "importFromNotion",
    });
    return {
      appState: {
        ...appState,
        open,
      },
      commitToHistory: false,
    };
  },
  predicate: (element, appState, props) => {
    //function to decide whether to show this option or not
    return typeof props.renderCommandPalette !== "undefined";
  },
  contextItemLabel: "buttons.notion", // right click option name
});


export const actionCreatePage = register({
  name: "createNotionPageForExisting",
  trackEvent: { category: "element" },
  perform: async (elements, appState, _data, app) => {
    dispatchCanvasEvent("contextOptionSelected", {
      type: "createNotionPageForExisting",
    });

    return {
      commitToHistory: false,
    };
  },
  contextItemLabel: "labels.createNotionPageForExisting",
});


export const actionLinkPage = register({
  name: "linkNotionPageForExisting",
  trackEvent: { category: "element" },
  perform: async (elements, appState, _data, app) => {
    dispatchCanvasEvent("contextOptionSelected", {
      type: "linkNotionPageForExisting",
    });

    return {
      commitToHistory: false,
    };

  },
  contextItemLabel: "labels.linkNotionPageForExisting",
});
