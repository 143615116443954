import { SVGProps } from "react";
export const NotionEmptyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="page"
    style={{
      width: "19.8px",
      height: "19.8px",
      display: "block",
      fill: "rgba(255,255,255,.443)",
      flexShrink: 0,
    }}
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#91918F"
      d="M4.356 15.468h7.28c1.464 0 2.222-.773 2.222-2.242v-6.2c0-.95-.123-1.388-.717-1.99l-3.59-3.65C8.979.805 8.507.668 7.652.668H4.356c-1.462 0-2.221.772-2.221 2.242v10.316c0 1.476.759 2.242 2.221 2.242Zm.11-1.34c-.663 0-.991-.349-.991-.984V2.992c0-.629.328-.984.99-.984h2.913v3.746c0 .977.485 1.45 1.456 1.45h3.685v5.94c0 .635-.335.984-.998.984H4.466Zm4.491-8.1c-.28 0-.396-.124-.396-.404V2.192l3.773 3.835H8.957ZM10.434 9H5.43a.428.428 0 0 0-.445.43.43.43 0 0 0 .445.438h5.004c.246 0 .43-.191.43-.437 0-.24-.184-.431-.43-.431Zm0 2.297H5.43a.43.43 0 0 0-.445.437c0 .24.185.424.445.424h5.004c.246 0 .43-.184.43-.424a.426.426 0 0 0-.43-.437Z"
    />
  </svg>
);
