import { CanvasEventTypes } from "./types";

export const dispatchCanvasEvent = (type: CanvasEventTypes, data: unknown) => {
  const newEvent = new CustomEvent(type, { detail: data });
  document.dispatchEvent(newEvent);
};

export const subscribeEvent = (
  eventName: CanvasEventTypes,
  listener: (event: CustomEvent) => void,
) => {
  document.addEventListener(
    eventName,
    listener as any as EventListenerOrEventListenerObject,
  );
};

export const unSubscribeEvent = (
  eventName: CanvasEventTypes,
  listener: (event: CustomEvent) => void,
) => {
  document.removeEventListener(
    eventName,
    listener as any as EventListenerOrEventListenerObject,
  );
};
