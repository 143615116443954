import { legacy_decodeFromBackend } from ".";
import { MIME_TYPES, serializeAsJSON } from "../../packages/excalidraw";
import {
  compressData,
  decompressData,
} from "../../packages/excalidraw/data/encode";
import { generateEncryptionKey } from "../../packages/excalidraw/data/encryption";
import { ImportedDataState } from "../../packages/excalidraw/data/types";
import { isInitializedImageElement } from "../../packages/excalidraw/element/typeChecks";
import {
  ExcalidrawElement,
  FileId,
} from "../../packages/excalidraw/element/types";
import { t } from "../../packages/excalidraw/i18n";
import {
  AppState,
  BinaryFileData,
  BinaryFileMetadata,
  BinaryFiles,
  DataURL,
} from "../../packages/excalidraw/types";
import { FILE_UPLOAD_MAX_BYTES } from "../app_constants";
import { encodeFilesForUpload } from "./FileManager";

const SERVER_URL = import.meta.env.VITE_APP_BACKEND_URL || "/api";
const SCENE_BACKEND_URL = `${SERVER_URL}/scene`;
const ASSETS_BACKEND_URL = `${SERVER_URL}/assets`;

interface CFFunctionsResponse<T> {
  data: T | null;
  error: {
    message: string;
  } | null;
}

export const exportToBackendR2 = async (
  elements: readonly ExcalidrawElement[],
  appState: Partial<AppState>,
  files: BinaryFiles,
  accessToken?: string,
) => {
  if (!accessToken) {
    return { url: null, errorMessage: "Login to continue" };
  }
  const encryptionKey = await generateEncryptionKey("string");

  const payload = await compressData(
    new TextEncoder().encode(
      serializeAsJSON(elements, appState, files, "database"),
    ),
    { encryptionKey },
  );

  try {
    const filesMap = new Map<FileId, BinaryFileData>();
    for (const element of elements) {
      if (isInitializedImageElement(element) && files[element.fileId]) {
        filesMap.set(element.fileId, files[element.fileId]);
      }
    }

    const filesToUpload = await encodeFilesForUpload({
      files: filesMap,
      encryptionKey,
      maxBytes: FILE_UPLOAD_MAX_BYTES,
    });

    const response = await fetch(SCENE_BACKEND_URL, {
      method: "POST",
      body: payload.buffer,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json: CFFunctionsResponse<{
      id: string;
    }> = await response.json();

    if (json.data?.id) {
      const url = new URL(window.location.href);
      // We need to store the key (and less importantly the id) as hash instead
      // of queryParam in order to never send it to the server
      url.hash = `json=${json.data.id},${encryptionKey}`;
      const urlString = url.toString();
      await saveFilesToR2({
        prefix: `/files/shareLinks/${json.data.id}`,
        files: filesToUpload,
      });
      return { url: urlString, errorMessage: null };
    } else if (json.error) {
      return {
        url: null,
        errorMessage: json.error.message,
      };
    }
    return { url: null, errorMessage: t("alerts.couldNotCreateShareableLink") };
  } catch (error: any) {
    console.error(error);
    return { url: null, errorMessage: t("alerts.couldNotCreateShareableLink") };
  }
};

export const importFromBackendR2 = async (
  id: string,
  decryptionKey: string,
): Promise<ImportedDataState> => {
  try {
    const response = await fetch(`${SCENE_BACKEND_URL}/${id}`);
    if (response.ok) {
      const buffer = await response.arrayBuffer();
      try {
        const { data: decodedBuffer } = await decompressData(
          new Uint8Array(buffer),
          {
            decryptionKey,
          },
        );
        const data: ImportedDataState = JSON.parse(
          new TextDecoder().decode(decodedBuffer),
        );

        return {
          elements: data.elements || null,
          appState: data.appState || null,
        };
      } catch (error: any) {
        console.warn(
          "error when decoding shareLink data using the new format:",
          error,
        );
        return legacy_decodeFromBackend({ buffer, decryptionKey });
      }
    }
    const json: CFFunctionsResponse<null> = await response.json();
    json.error && window.alert(json.error.message);
    return {};
  } catch (error: any) {
    window.alert(t("alerts.importBackendFailed"));
    console.error(error);
    return {};
  }
};

export const saveFilesToR2 = async ({
  prefix,
  files,
}: {
  prefix: string;
  files: { id: FileId; buffer: Uint8Array }[];
}) => {
  const erroredFiles = new Map<FileId, true>();
  const savedFiles = new Map<FileId, true>();

  await Promise.all(
    files.map(async ({ id, buffer }) => {
      try {
        const response = await fetch(`${ASSETS_BACKEND_URL}${prefix}/${id}`, {
          method: "POST",
          body: new Blob([buffer], {
            type: MIME_TYPES.binary,
          }),
        });
        if (response.ok) {
          savedFiles.set(id, true);
        } else {
          erroredFiles.set(id, true);
        }
      } catch (error: any) {
        erroredFiles.set(id, true);
      }
    }),
  );

  return { savedFiles, erroredFiles };
};

export const loadFilesFromR2 = async (
  prefix: string,
  decryptionKey: string,
  filesIds: readonly FileId[],
) => {
  const loadedFiles: BinaryFileData[] = [];
  const erroredFiles = new Map<FileId, true>();

  await Promise.all(
    [...new Set(filesIds)].map(async (id) => {
      try {
        const url = `${import.meta.env.VITE_APP_ASSETS_URL}${prefix}/${id}`;
        const response = await fetch(url);
        if (response.status < 400) {
          const arrayBuffer = await response.arrayBuffer();

          const { data, metadata } = await decompressData<BinaryFileMetadata>(
            new Uint8Array(arrayBuffer),
            {
              decryptionKey,
            },
          );

          const dataURL = new TextDecoder().decode(data) as DataURL;

          loadedFiles.push({
            mimeType: metadata.mimeType || MIME_TYPES.binary,
            id,
            dataURL,
            created: metadata?.created || Date.now(),
            lastRetrieved: metadata?.created || Date.now(),
          });
        } else {
          erroredFiles.set(id, true);
        }
      } catch (error: any) {
        erroredFiles.set(id, true);
        console.error(error);
      }
    }),
  );

  return { loadedFiles, erroredFiles };
};
