import React, { useState } from "react";
import { useSidePeek } from "../../../hooks/useSidePeek";
import { ExcalidrawNotionElement } from "../../../../packages/excalidraw/element/types";
import { SidePeekOpenIcon } from "../../../assets/SidePeekOpenIcon";
import { NotionIcon } from "../../../../packages/excalidraw/components/icons";
import {
  NotionIconElementType,
  processNotionIcon,
} from "../../../helper/canvasUtils";
import { ArrowUpRightIcon } from "@heroicons/react/24/outline";
import { useAuth } from "../../../hooks/useAuth";
import { classNames } from "../../../helper/classNames";
import { AppState, SceneData } from "../../../../packages/excalidraw/types";
interface Props {
  element: ExcalidrawNotionElement;
  parentStyle: React.CSSProperties;
  updateScene?: <K extends keyof AppState>(sceneData: {
    elements?: SceneData["elements"];
    appState?: Pick<AppState, K> | null | undefined;
    collaborators?: SceneData["collaborators"];
    commitToHistory?: SceneData["commitToHistory"];
  }) => void;
}

export const RenderNotion: React.FC<Props> = ({
  element,
  parentStyle,
  updateScene,
}) => {
  const { setPageId, setShow, show, setPublicUrl, setUpdateElement } = useSidePeek();
  const [isHovered, setIsHovered] = useState(false);
  const { session } = useAuth();
  const showSidePeek = () => {
    if (show === false) {
      setShow(true);
    }
    updateScene && setUpdateElement({ scene: updateScene });
    setPageId(element.pageId || "");
    setPublicUrl(element?.publicUrl || "");
  };

  const pageId = element?.pageId && element.pageId.replace(/-/g, "");
  return (
    <div
      onPointerEnter={() => setIsHovered(true)}
      onPointerLeave={() => setIsHovered(false)}
    >
      {isHovered && (
        <div className="absolute w-fit h-fit rounded-md flex items-center top-3 right-2 z-30 justify-end">
          <div className="flex border border-gray-800 rounded-md  items-center">
            <>
              <a
                href={`https:///notion.so/${pageId || "/"}`}
                target="_blank"
                className={classNames(
                  "p-2   rounded-l-md block",
                  session ? "border-r border-gray-800 " : "",
                )}
                // onClick={showSidePeek}
                rel="noreferrer"
              >
                <ArrowUpRightIcon className="text-gray-800 w-4 h-4" />
              </a>
              {/* {session && ( */}
              <button
                type="button"
                className="p-2  rounded-md"
                onClick={showSidePeek}
              >
                <SidePeekOpenIcon />
              </button>
              {/* )} */}
            </>
          </div>
        </div>
      )}
      <div
        className={`p-3 rounded-2xl border overflow-hidden border-zinc-950 `}
        style={parentStyle}
      >
        <div className="notion-cover-container flex justify-between">
          <div className="w-8 h-8">{NotionIcon}</div>
        </div>
        <div className="notion-card-body">
          <div className="notion-card-details flex items-center mt-3">
            <div className="w-8 h-8 mx-1 text-2xl flex items-center justify-start">
              {processNotionIcon(element.icon as NotionIconElementType)}
            </div>
            <div className="notion-card-title py-1  text-xl whitespace-pre-wrap overflow-scroll ">
              {element.title}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
