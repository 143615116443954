import {
  Provider as SupabaseProvider,
  Session,
  UserMetadata,
} from "@supabase/supabase-js";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { supabase } from "../config/supabase";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../helper/localStorage";

interface AuthContext {
  user: UserMetadata;
  session?: Session;
  signIn: (provider: SupabaseProvider) => Promise<void>;
  signOut: () => Promise<void>;
}

const authContext = createContext({ user: {} } as AuthContext);
const { Provider } = authContext;

// AuthProvider is a Context Provider that wraps our app and makes an auth object
// available to any child component that calls the useAuth() hook.
export function AuthProvider(props: { children: ReactNode }): JSX.Element {
  const auth = useAuthProvider();
  return <Provider value={auth}>{props.children}</Provider>;
}

// useAuth is a hook that enables any component to subscribe to auth state
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
const useAuthProvider = () => {
  const [user, setUser] = useState({});
  const [session, setSession]: [
    Session | undefined,
    Dispatch<SetStateAction<Session | undefined>>,
  ] = useState();

  const signIn = async (provider: SupabaseProvider) => {
    try {
      setLocalStorage("isUserLoggedIn", "false");
      await supabase.auth.signInWithOAuth({
        provider,
        // options: {
        //   redirectTo: `${
        //     import.meta.env.VITE_SITE_URL || "http://localhost:3000"
        //   }/auth/verify`,
        // },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const signOut = async () => {
    removeLocalStorage(["isUserLoggedIn"]);
    await supabase.auth.signOut();
  };

  useEffect(() => {
    supabase.auth.getSession().then((response) => {
      if (response.data.session) {
        setSession(response.data.session);
      }
    });
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      session ? setSession(session) : setSession(undefined);
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (session?.user?.user_metadata) {
      setUser(session.user.user_metadata);
    }

    const isUserLoggedIn = getLocalStorage("isUserLoggedIn");
    //temp fix need to find permenant solution
    if (
      session?.provider_token &&
      session?.user?.id &&
      isUserLoggedIn === "false"
    ) {
      supabase
        .from("provider_tokens")
        .upsert(
          {
            user_id: session.user.id,
            provider: "notion",
            provider_token: session.provider_token,
          },
          {
            onConflict: "user_id",
          },
        )
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setLocalStorage("isUserLoggedIn", "true");
          }
        });
    }
  }, [session?.provider_token, session?.user]);

  return {
    user,
    session,
    signIn,
    signOut,
  };
};
