import { useAuth } from "../../../hooks/useAuth";
import { Button } from "../../../../packages/excalidraw";
import React from "react";

const Authentication: React.FC = () => {
  const { signIn } = useAuth();

  const handleNotionIntegration = async () => {
    await signIn("notion");
  };

  return (
    <div className="w-full min-h-32 flex flex-col justify-center items-center">
      <h1 className=" my-5 text-2xl font-regular"> Login to continue </h1>
      <Button
        className="notion-integration-btn bg-royal-blue-500 px-4 py-3 rounded-md text-white"
        type="button"
        onSelect={handleNotionIntegration}
      >
        Connect with Notion
      </Button>
    </div>
  );
};
export default Authentication;
