import { SearchParameters } from "@notionhq/client/build/src/api-endpoints";
import { supabase } from "../config/supabase";

type NotionRecordMap = {
  pageId: string;
  publicUrl: string | null;
};

export const notionSearch = (query: SearchParameters) => {
  return supabase.functions.invoke("notion-integration", {
    method: "POST",
    body: query,
  });
};

export const notionPage = (pageId: string) => {
  return supabase.functions.invoke(`notion-integration?pageId=${pageId}`, {
    method: "GET",
  });
};

export const notionDatabase = (databaseId: string) => {
  return supabase.functions.invoke(
    `notion-collection?databaseId=${databaseId}`,
    {
      method: "GET",
    },
  );
};

export const notionPageRecordMap = (data: NotionRecordMap) => {
  return supabase.functions.invoke(`notion-recordmap`, {
    method: "POST",
    body: data,
  });
};

export const createPage = (data: any) => {
  return supabase.functions.invoke(`create-notion?pageId=${data.pageId}`, {
    method: "POST",
    body: {
      pageTitle: data.pageTitle,
      type: data.type,
    },
  });
};
