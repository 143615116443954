import { classNames } from "../../../helper/classNames";
import { Dispatch, useState, SetStateAction, useEffect } from "react";
import {
  CommandPaletteRendererInterface,
  ExcalidrawElement,
} from "../../../../packages/excalidraw/element/types";
import { createPage } from "../../../services/notion";
import { getPageTitle } from "../../../helper/canvasUtils";
import { MouseEventType } from "../../../types";
import Search from "./Search";
import {
  handleSupabaseError,
  storeArrowElements,
  handleUpdateScene,
} from "../../../services/utils";
import { elementsDataStore } from "../../../services/utils";
interface NotionPage {
  id?: string;
  title?: string;
  type?: string;
  icon?: {
    type: string;
    [key: string]: string;
  };
  publicUrl: string | null;
  notionUpdatedAt?: number;
}
interface Props extends CommandPaletteRendererInterface {
  mouseClickEvent: MouseEventType;
  eventType?: string;
  renderPage?: boolean;
  closeModal: () => void;
  element?: any;
}

const CreatePage: React.FC<Props> = ({
  render,
  mouseClickEvent,
  closeModal,
  eventType,
  renderPage,
  element,
  excalidrawAppState,
}) => {
  const [selectPage, setSelectPage]: [
    NotionPage,
    Dispatch<SetStateAction<NotionPage>>,
  ] = useState({} as NotionPage);

  const [createPageLoad, setCreatePageLoad] = useState(false);
  const [notionPageTitle, setNotionPageTitle] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (
      (eventType === "linkNotionPageForExisting" ||
        eventType === "createNotionPageForExisting") &&
      element
    ) {
      const textElement: { type: string; id: "string" }[] =
        element.element?.boundElements?.filter(
          (element: ExcalidrawElement) => element.type === "text",
        );
      if (textElement && textElement.length) {
        const listData: ExcalidrawElement[] = elementsDataStore();
        const text: any = listData.find(
          (item: any) => item.id === textElement[0].id,
        );

        if (text?.originalText) {
          setNotionPageTitle(text.originalText);
        }

      }
    }
  }, [element, eventType]);

  const handleCreatePageRender = (selectedPage: NotionPage) => {
    if (selectedPage.id && render) {
      render({
        type: "notion",
        integrationSpecificData: {
          title: getPageTitle(selectedPage),
          pageId: selectedPage.id,
          pageType: "page",
          icon: selectedPage.icon,
          publicUrl: selectedPage.publicUrl,
        },
        pointerDownCoords: mouseClickEvent.pointerDownCoordinates,
      });
      closeModal();
    }
  };

  const importData = (selectedPage: NotionPage) => {
    if (eventType === "createNotionPageForExisting" && element && selectPage) {
      handleCreatePageRender(selectedPage);
      setTimeout(() => {
        storeArrowElements();
        handleUpdateScene(excalidrawAppState, element);
      }, 400);
    } else if (mouseClickEvent?.event && selectedPage.id) {
      handleCreatePageRender(selectedPage);
    } else if (selectPage.id && eventType === "notion") {
      handleCreatePageRender(selectedPage);
    }
  };

  const handleCreatepage = async () => {
    if (selectPage && selectPage.id && selectPage.title) {
      setCreatePageLoad(true);
      const data = {
        pageId: selectPage.id,
        pageTitle: notionPageTitle,
        type: selectPage.type,
      };
      await createPage(data)
        .then((res) => {
          if (res.data !== null) {
            importData(res.data);
            setCreatePageLoad(false);
          } else {
            res.error &&
              handleSupabaseError(res.error, setError, setCreatePageLoad);
            setTimeout(() => {
              setError("");
            }, 5000);
            setCreatePageLoad(false);
          }
        })
        .catch(() => {
          setCreatePageLoad(false);
        });
    }
  };
  return (
    <>
      {error && (
        <p className="text-xs flex justify-center w-full text-red-700  text-end  mb-4 px-4 py-1 rounded-md bg-red-100">
          {error}
        </p>
      )}
      <div className="mt-3 pb-5">
        <label className="mb-3 block font-medium  text-gray-700">
          Enter title for new notion page
        </label>
        <input
          className="border-[1.5px] border-gray-400 rounded-xl w-full py-3 px-4 text-md focus-visible:border-[1.5px] focus-visible:border-indigo-400 focus-visible:outline-0"
          type="text"
          placeholder="Enter Notion page title"
          onChange={(e) => setNotionPageTitle(e.target.value)}
          value={notionPageTitle}
        />
      </div>
      <div className="mt-3">
        <label className="mb-2 block font-medium  text-gray-700">
          Search for notion parent page
        </label>

        <Search
          render={render}
          eventType={eventType}
          mouseClickEvent={mouseClickEvent}
          closeModal={closeModal}
          setSelectedPage={setSelectPage}
          selectedPage={selectPage}
          renderPage={renderPage}
          element={element}
        />
      </div>
      {selectPage && selectPage?.title && (
        <div className="flex justify-between">
          <div></div>
          <button
            disabled={!notionPageTitle || createPageLoad}
            onClick={handleCreatepage}
            className={classNames(
              "bg-[#6965db]  text-white py-3  text-sm px-5 mt-8 rounded-md",
              notionPageTitle ? "" : "opacity-60",
              createPageLoad ? "opacity-90" : "",
            )}
          >
            {createPageLoad ? "Loading..." : "Create a Notion page"}
          </button>
        </div>
      )}
    </>
  );
};

export default CreatePage;
